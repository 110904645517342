.tcard {
    display: flex;
    flex-direction: column;
    width: 18rem;
    height: 400px;
    border-radius: 4px;
    margin-top: 60px;
}

.tcard .img-container {
    height: 300px;
}

.tcard .img-container img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

}

.tcard .body {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.topic-link:hover {
    color: #f9a109;
}