@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
* {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar .brand-img {
    height: 50px;
}

.navbar .nav-drop a {
    color: black;
}

.navbar .nav-drop a::after {
    content: none;
}

.navbar .btn-link {
    color: black;
    padding: .2rem .5rem;
}

.navbar .btn-link:focus,
.navbar .btn-link:active {
    outline: none;
    box-shadow: none;
}

.carousel-card {
    object-fit: cover;
    margin-top: 60px;
    width: 40%;
}

@media only screen and (max-width: 600px) {
    .carousel-card {
        width: 100%;
    }

}
.home {
    display: flex;
    justify-content: space-around;
}

@media only screen and (max-width: 600px) {
    .home {
        display: flex;
        justify-content: stretch;
    }
}
.App {
  text-align: center;
}

html {
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.row1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.row2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.card-img.pic-style {
  width: 30%;
}

.space-above {
  margin-top: 3%;
}

.background-match {
  background-color: inherit;
}

.app-shell {
  display: flex;
  flex-direction: column;
}
.tcard {
    display: flex;
    flex-direction: column;
    width: 18rem;
    height: 400px;
    border-radius: 4px;
    margin-top: 60px;
}

.tcard .img-container {
    height: 300px;
}

.tcard .img-container img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

}

.tcard .body {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.topic-link:hover {
    color: #f9a109;
}
.main {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
