.home {
    display: flex;
    justify-content: space-around;
}

@media only screen and (max-width: 600px) {
    .home {
        display: flex;
        justify-content: stretch;
    }
}