.App {
  text-align: center;
}

html {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.row2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.card-img.pic-style {
  width: 30%;
}

.space-above {
  margin-top: 3%;
}

.background-match {
  background-color: inherit;
}

.app-shell {
  display: flex;
  flex-direction: column;
}